/* authenticatorStyles.css */
[data-amplify-authenticator] {
    --amplify-components-authenticator-router-border-width: 0;
    --amplify-components-authenticator-form-padding: 10% 20% 10%;
    --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px #FC5373;
    --amplify-components-tabs-item-active-border-color: #FC5373;
    --amplify-components-tabs-item-color: #9b9b9b;
    --amplify-components-tabs-item-active-color: #1A2754;
    --amplify-components-button-link-color: #1A2754;
}

[data-amplify-authenticator] .amplify-button--primary[type="submit"] {
    background-image: linear-gradient(45deg, #FC5373, #FDBD80) !important;
    border: none !important;
    color: white !important; /* Adjust text color as needed */
}

/* .amplify-button:not(.amplify-button--disabled) {
    background-image: linear-gradient(45deg, #FC5373, #FDBD80);
    border: none !important;
    color: white !important; /* Adjust text color as needed */
/* } */

.amplify-label {
    font-size: 14px;
    color: #a0aec0;
    margin-top: 0.25rem;
}

.amplify-text {
    font-size: 14px;
    color: #1A2754;
    margin-top: 0.25rem;
}